
// Importando Swiper e os estilos necessários

import jquery from 'jquery';

window.$ = window.jQuery = jquery;

if ($(window).width() < 575) {
    $(function() {

        let cards = gsap.utils.toArray(".data-info-wrapper");

        let stickDistance = 0;

        let firstCardST = ScrollTrigger.create({
            trigger: cards[0],
            start: "center center"
        });

        let lastCardST = ScrollTrigger.create({
            trigger: cards[cards.length-1],
            start: "center center"
        });

        cards.forEach((card, index) => {

            var scale = 1 - (cards.length - index) * 0.045;
            let scaleDown = gsap.to(card, {scale: scale, 'transform-origin': '"50% '+ (lastCardST + stickDistance) +'"' });

            ScrollTrigger.create({
                trigger: card,
                start: "top top",
                end: () => lastCardST.start + stickDistance,
                pin: true,
                markers: false,
                pinSpacing: false,
                ease: "none",
                animation: scaleDown,
                toggleActions: "restart none none reverse"
            });
        });

    });

}